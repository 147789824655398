.section {
    background-color: #f8f9fa;
    border-radius: 5px;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#about {
    flex-direction: column;
}

.section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 15px;
    margin-top: 15px;
}

.section-text {
    margin-bottom: 15px;
}

/* Media query for larger screens */
@media (min-width: 768px) {
    .section-content {
        flex-direction: row;
        justify-content: space-around;
    }

    .section-img {
        margin-right: 15px;
        margin-bottom: 15px;
    }

    .section-text {
        margin-left: 15px;
    }
}
