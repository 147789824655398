.serviceTimes h1 {
    text-align: center;
    padding: 0px;
    margin: 0px;
    margin-top: 20px;
}

.serviceTimes ul {
    display: block;
    margin: 0;
    margin-bottom: 10px;
    list-style-type: none;
    padding: 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.serviceTimes li {
    font-weight: 500;
}

.serviceDay {
    text-align: left;
}

.serviceTime {
    text-align: right;
}

.one, .three, .five {
    color: #32373c;
}

.two, .four {
    color: #0c0d0e;
}
