.dropdown {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
    background: white;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown-content a {
    display: block;
    color: #32373c;
    padding: 5px;
    text-decoration: none;
  }
  .dropdown-content a:hover {
    color: white;
    background-color: #007cba;
    transition: 0.3s;
  }

.moreTitle {
    font-size: 25px;
    font-weight: 600;
    padding-top: 20px;
    transition: color 0.3s;
    padding-bottom: 10px;
}

.moreTitle:hover {
    color: #007cba;
    cursor: pointer;
}

.arrow {
    position: relative;
    top: 4px;
}