.mobileMenu{
  background-color: white;
  opacity: 95%;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  justify-content: flex-start; /* Changed to flex-start */
  position: fixed;
  top: 56px;
  width: 100%;
  height: calc(100vh - 56px - 42px); /* adjust the height to take full viewport height minus top */
  z-index: 101;
}

.menuContainer {
  display: flex;
  flex-direction: column; /* Added */
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 60px;
}

.mobileMenu a {
  transition: color 0.3s;
  opacity: 100%;
}

.mobileMenu a:hover {
  color: #007cbb;
  opacity: 100%;
}

.social {
  padding: 0;
  margin: 0;
}

.social li {
  display: inline-flex;
  font-size: 25px;
  text-decoration: none;
}

.policy a {
  font-size: 12px;
}
