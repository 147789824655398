.secondaryContainer {
    color: #222533;
    font-family: "Montserrat", sans-serif;
    display: flex;
    opacity: 95%;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    position: fixed;
    top: 56px;
    width: 100%;
    z-index: 100;
    font-weight: 300;
  }

  .secondaryContainer a {
      font-size: 18px;
      text-transform: none;
      font-weight: 300;
      
  }