.linkHeaders {
    display: block;
}

.linkHeaders a {
    font-size: 25px;
    font-weight: 500;
    display: block;
    padding: .9px;
    margin: 0;
    text-align: center;
}

.secondarySocialContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.secondarySocial a {
    font-size: 25px;
}
