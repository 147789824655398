.active {
    color: #007cba;
}

a {
    color: #32373c;
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    transition: color 0.3s;
    padding: 10px;
}

a:hover {
    color: #007cba;
}