.location a {
    padding: 0;
    margin: 0;
}

.location {
    text-align: center;
}

.location a:hover {
    cursor: pointer;
}

.location h1 {
    padding: 0px;
    margin: 0;
}