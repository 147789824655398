.refundPolicy {
    font-family: "Montserrat", sans-serif;
    background-color: white;
    /*color: #32373c;*/
    color: #32373c;
    margin-bottom: 50px;
}

.refundPolicy h1, .refundPolicy h3 {
    padding: 10px;
    padding-bottom: 0px;
    text-align: center;
}

.refundPolicy p {
    text-align: center;
    padding-top: 0;
    margin: 0;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.refundPolicy ul {
    list-style-position: inside;
    text-align: center;
}

.refundPolicy li {
    padding: 5px;
    font-size: 13px;
    text-align: left;
}

strong {
    color: #007cba;
}

.refundPolicy p a {
    font-size: 14px;
    color: #32373c;
}

.refundPolicy p a:hover {
    color: #007cba;
}