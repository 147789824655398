.more {
    font-size: 30px;
    padding: 10px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    color: #404140;
    transition: color 0.3s;
}

.more:hover { 
    color: #007cbb;
    cursor: pointer;
}

.moreContainer ul {
    list-style-type: none;
    margin: 0;
}

.moreContainer li {
    padding: none;
    margin: 0;
}

.moreContainer li a{
    font-size: 20px;
    padding: 0;
}
