body {
    margin: 0; 
    background-color: whitesmoke;
    scroll-behavior: smooth;
    height: 100%;
    min-height: 100%;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./../node_modules/font-proxima-nova/fonts/ProximaNova-Black.ttf');
}
