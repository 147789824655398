@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container {
  width: 100%;
  background-color: white;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  align-items: center;
  z-index: 100;
  position: fixed;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.bannerItems {
  position: absolute;
  right: 70px;
}

#logo {
  height: 1.6em;
  object-fit: contain;
  float: left imp !important;
  position: relative;
  top: 5px;
  padding-top: '5px';
  padding-bottom: '5px';
}

#logoNav {
  display: inline-block;
  padding: 0;
  margin: 0;
}

#logoNav:hover {
  cursor: pointer;
}